export enum SEGMENT {
	"OS" = "Official",
	"GS" = "Greyseller",
	"RS" = "OARP (Reseller)",
	"CP" = "Competitor",
	"OT" = "Others"
}

export enum SEGMENTCOLOR {
	"OS" = "#EE6000",
	"GS" = "#8198bc",
	"RS" = "#FF9800",
	"CP" = "#4284F3",
	"OT" = "#C0CA33"
}

export enum PRODUCTTYPE {
	"available to purchase" = "#FF5E00",
	"out of stock" = "#DB0042",
	"new launches" = "#770050",
	"newly tracked" = "#770050",
}

export enum PRODUCTTYPEABBR {
	"Available to Purchase" = "atp",
	"Out Of Stock" = "oos",
	"Newly Tracked" = "new_launches",
	"Out of Stock" = "oos"
}

export enum PRODUCTTYPEABBRRREVERSE {
	"atp" = "Available to Purchase",
	"oos" = "Out Of Stock",
	"new_launches" = "Newly Tracked"
}

export enum PRODUCTTYPEEXPORT {
	"atp" = "atp",
	"oos" = "oos",
	"new_launches" = "newly_tracked"
} 
export enum SEGMENTREVERSE {
	"Official" = "OS",
	"Greyseller" = "GS",
	"OARP (Reseller)" = "RS",
	"Competitor" = "CP",
	"Others" = "OT"
}

export enum LOCALFILTER {
	"STORE" = "store",
	"BRAND" = "brand",
	"SKU" = "sku",
	"CATEGORY" = "category",
	"CATEGORY_L1" = "category_l1",
	"CATEGORY_L2" = "category_l2",
	"SEGMENT" = "segment",
	"STATUS" = "status",
	"RETAIL_CHANNEL" =  "retail_channel",
	"AI_Services" = "aiService",
	"PRODUCT" = "product",
	"TAGS" = "tags",
	"COMPETITOR_BRAND" = "competitor_brand",
	"COUNTRY" = "country",
	"CURRENCIES" = "currencies",
	"PRICE_RANGE" = "price_range",
	"RATINGS" = "ratings",
	"PRODUCT_SEGMENT" = "product_segment"
}

export enum GRIDTYPE {
	"ALL" = "all",
	"USERONLY" = "userOnly",
	"HARMONISED" = "harmonised"
}
export enum TOOLTIPCOLOR {
	"BG" = "#121737",
	"TEXT" = "#BDBDBD",
	"VALUE" = "#FFFFFF"

}

export enum FILTERPAYLOAD {
	"MARKETPLACES" = "filter[marketplaces]",
	"STORES" = "filter[stores]",
	"CATEGORIES" = "filter[categories]",
	"CATEGORIES_L1" = "filter[categories_l1]",
	"CATEGORIES_L2" = "filter[categories_l2]",
	"BRANDS" = "filter[brands]",
	"PRODUCTS" = "filter[product]",
	"SEGMENTS" = "filter[segment]",
	"PRODUCT_COLLECTION_SEGMENTS" = "filter[product_collection_segments]"
}

export const enum COL  {
	PRODUCT = "Product",
	PRODUCT_NAME = "Product Name",
	CURRENT_PRICE = "Current Price",
	PRICE = "Price",
	STORE = "Store",
	BRAND = "Brand",
	CATEGORY = "Category",
	RETAIL_CHANNEL = "Channel",
	STORE_SEGMENT = "Seller Segment",
	QUANTITY = "Quantity",
	RATINGS = "Rating",
	LIKES = "Likes",
	LAST_DISCOUNT = "Last Discount",
	DISCOUNT = "Discount",
	LAST_UPDATED = "Last Updated",
	OOS_DAYS = "Out Of Stock For (Days)",
	REVENUE_LOSS = "Revenue Loss",
	LAST_PRICE = "Last Price",
	SEGMENT = "Seller Segment",
	AVG_SELLING_PRICE = "Avg. Selling Price",
	QUANTITY_SOLD =  "Quantity Sold",
	GMV = "GMV",
	CURRENT_SALES = "Current Estimated Sales",
	LAST_SALES = "Last Estimated Sales",
	STATUS = "Status",
	RATING ="RATING",
	DATE="Date",
	SKU="SKU",
	DEALER_REG_NO="Dealer Reg No.",
	AVERAGE_PRICE="Average Price",
	AVERAGE_DISCOUNT="Average Discount",
	PROMOTION_APPLIED="Promotion Applied",
	SELL_IN = "Sell In",
	CREATED_AT = "Created At",
	UPDATED_AT = "Updated At",
	KPI_LAST_UPDATED = "KPI Last Updated",
	PRICE_RANGE = "Price Range",
	AI_SERVICES = "AI Services",
	PDP_LIMIT = "PDP Limit",
	REVENUE_SHARE = "Revenue Share",
	HISTORICAL_SOLD = "Historical Sold",
	ESTIMATED_SALES = "Estimated Sales",
	START_DATE= "Start Date",
	END_DATE = "End Date",
	BUZZWORDS = "#Buzzwords",
	CONTENT_QUALITY = "Content Quality",
	PROMO_COUNT = "No. of Promotions",
	PERCENTAGE_OVERPRICED = "Percentage Overpriced",
	PERCENTAGE_UNDERPRICED = "Percentage Underpriced",
	VARIANTS = "Variants",
	CURRENT_DISCOUNT= 'Current Discount',
	AVERAGE_RATING = 'Average Rating',
	COUNTRY = 'Country',
	CURRENT_QUANTITY_SOLD = "Current Quantity Sold",
	LAST_QUANTITY_SOLD = "Last Quantity Sold",
	PRODUCT_COLLECTION = 'Product Collection',
	SKU_NAME = "SKU Name",
	OFFICIAL_PRICE = "Official Price",
	IS_LOWEST = "Is Lowest",
	LOWEST_PRICE = "Lowest Price",
	MAPPEDTO = 'Mapped To',
	TREND = "Trend",
	PRODUCT_SEGMENT = "Product Segment"
}

export const enum PAYLOAD {
	CURRENCY_ID = "currency_id",
	TIMELINE = "timeline",
	MARKETPLACE_ID = "marketplace_id",
	TYPE = "type",
	SEGMENT = "segment",
	CATEGORY = "category",
	VIEW = "view",
	DATE = "date",
	HOUR = "hour",
	START_RANGE = "start_range",
	END_RANGE = "end_range",
	SEARCH = "search",
	MAX_PRICE = "max_price",
	MIN_PRICE = "min_price",
	ALL_PRODUCTS = "all_products",
	STORE_ID = "store_ids",
	PROFILES = "profiles",
	FILTER_MARKETPLACE_ID = "filter[marketplace_id]",
	POPUP = "popup",
	START_DATE = "start_date",
	END_DATE = "end_date",
	TREND_VIEW = "trend_view",
	SHARE_TYPE = "share_type",
	SHARE_ON = "share_on",
	SEGMENTS = "segments",
	AGGREGATION = "aggregation",
	GRIDTYPE = 'grid_type',
	BRAND_ID = "brand_ids",
	CATEGORY_ID = "category_ids",
	SEGMENTATION_TYPE = "segmentation_type",
	CALCULATION_TYPE = "calculation_type",
	HARMONISED_CURRENCY = "harmonisation_currency_id",
	SKU = "skus",
	HARMONISED_TYPE = "harmonisation_type"
 }

export const enum TIMELINE {
	PREVIOUS_MONTH = "previous_month",
	CURRENT_MONTH = "current_month",
	YESTERDAY = "yesterday",
	CURRENT_WEEK = "current_week",
	PREVIOUS_WEEK = "previous_week",
	TODAY = "today",
	LAST_30_DAYS = "last_30_days",
	LAST_90_DAYS = "last_90_days",
	THIS_MONTH = 'this_month',
	LAST_60_DAYS = 'last_60_days'
}

export enum AI_SERVICES{
	'cq' = 'Content Quality',
	'sa' = 'Sentiment Analysis',
	'pm' = 'Product Matching'
}

export enum TIMEVIEW{
	'WEEKLY'='weekly',
	'DAILY'='daily',
	'HOURLY'='HOURLY'
}

export enum INSIGHT_CARDS{
	"COUNT" = "count",
	"GRID" = "rows",
}

export enum USE_TYPE{
	"CUSTOMER" = "customer",
	"COMPETITOR" = "competitor",
}

export enum COMMONTEXT {
	'RETAIL_CHANNEL' = 'Channel'
}

export enum SCORECARD_TYPE {
	'product' = 'product',
	'store' = 'store',
	'brand' = 'brand',
	'keyword' = 'keyword',
	'share-of-category'= 'category'
  }